<template>
  <a-table rowKey="id" :data-source="list" :pagination="false" :loading="listLoading">
    <a-table-column key="index" title="序号" align="center" :ellipsis="true" width="10%">
      <template slot-scope="text, record, index">
        <span>{{ index + 1 }}</span>
      </template>
    </a-table-column>

    <a-table-column key="code" title="题目编号" align="center" data-index="code" :ellipsis="true" width="12%">
      <template slot-scope="code">
        <span>
          {{ formatCode(code) }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="questionStem"
      title="题干"
      align="center"
      data-index="questionStem"
      :ellipsis="true"
      width="20%"
    >
      <template slot-scope="questionStem">
        <span class="primary-color">
          {{ questionStem }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="skillList"
      title="关联技能包"
      align="center"
      data-index="skillList"
      :ellipsis="true"
      width="20%"
    >
      <template slot-scope="skillList">
        <span>
          {{ skillList.map(v => v.name).join('、') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column key="status" title="启用状态" align="center" data-index="status" :ellipsis="true" width="13%">
      <template slot-scope="status">
        <a-badge
          :status="{ AVAILABLE: 'success', UNAVAILABLE: 'error' }[status]"
          :text="status | StatusText()"
        ></a-badge>
      </template>
    </a-table-column>

    <a-table-column
      key="modifiedTimestamp"
      title="更新时间"
      align="center"
      data-index="modifiedTimestamp"
      :ellipsis="true"
      width="18%"
    >
      <template slot-scope="modifiedTimestamp">
        <span>
          {{ modifiedTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="17%">
      <template slot-scope="record">
        <a-space>
          <a-button v-if="$auth('subjects_update')" type="link" @click="handleEdit(record)">
            <a-icon type="edit" />编辑
          </a-button>
          <a-button v-if="$auth('subjects_delete')" type="link" @click="handleRemove(record)" class="error-color">
            <a-icon type="delete" />删除
          </a-button>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import storage from 'store'
import * as subjectApi from '@/api/subject'

export default {
  data() {
    return {
      query: {},
      listLoading: false,
      list: [],
      sortAble: null
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
    // 设置滚动条高度
    document.querySelector('html').scrollTop = storage.get('subject_scroll_top')
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    // 最多显示3位code
    formatCode(code) {
      const _code = (code + '').substr(8)
      const codeLengthLimit = 3
      return (Array(codeLengthLimit).join(0) + _code).slice(-codeLengthLimit)
    },
    initData(query = {}) {
      const { getData } = this

      this.query = query

      const { fieldId } = query
      if (fieldId) {
        this.handleSortable()
      } else {
        this.sortAble && this.sortAble.destroy()
        this.sortAble = null
      }

      return getData()
    },
    getData() {
      const { $nextTick, query } = this

      this.listLoading = true

      return subjectApi
        .list({ ...query })
        .then(res => {
          // 刷新序号
          this.list = []
          $nextTick(() => {
            this.list = cloneDeep(res.data)
          })

          // 选择技能包
          const tempList = []
          res.data.map(item => {
            item.skillList.forEach(item1 => {
              tempList.push(item1.name)
            })
          })

          this.$emit('getSkillList', new Set(tempList))
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleSortable() {
      const tbody = this.$el.querySelector('.ant-table-tbody')

      this.sortAble = Sortable.create(tbody, {
        onEnd: ({ newIndex, oldIndex }) => {
          const { $nextTick, list } = this
          const currRow = list.splice(oldIndex, 1)[0]
          list.splice(newIndex, 0, currRow)
          const sorted = list

          this.list = []
          $nextTick(() => {
            this.list = sorted
            this.$emit('sort')
          })
        }
      })
    },
    // 编辑
    handleEdit({ id }) {
      // 获取滚动条高度
      const scrollTop = document.querySelector('html').scrollTop
      storage.set('subject_scroll_top', scrollTop)
      // 跳转编辑
      this.$router.push({
        path: '/subject/edit/' + id
      })
    },
    handleRemove(record) {
      const { $confirm, $notification, $info, getData } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除当前题目',
        onOk: () => {
          return subjectApi
            .remove(record.id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              getData()
            })
            .catch(err => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@media (max-width: 1919px) {
  /deep/ .ant-badge-status-text,
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
