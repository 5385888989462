import request from '@/utils/request'

const subjectApi = {
  list: '/subjects',
  create: '/subjects',
  detail: id => `/subjects/${id}`,
  update: id => `/subjects/${id}`,
  remove: id => `/subjects/${id}`,
  sort: '/subjects/sorts',
  updateStatus: id => `/subjects/status/${id}`
}

export function list(parameter) {
  return request({
    url: subjectApi.list,
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: subjectApi.create,
    method: 'post',
    data: parameter
  })
}

export function detail(parameter) {
  return request({
    url: subjectApi.detail(parameter),
    method: 'get'
  })
}

export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: subjectApi.update(id),
    method: 'put',
    data: parameter
  })
}

export function remove(parameter) {
  return request({
    url: subjectApi.remove(parameter),
    method: 'delete'
  })
}

export function sort(parameter) {
  return request({
    url: subjectApi.sort,
    method: 'put',
    data: parameter
  })
}

export function updateStatus(parameter) {
  return request({
    url: subjectApi.updateStatus(parameter),
    method: 'patch'
  })
}
