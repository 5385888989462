<template>
  <a-row :gutter="24" style="overflow: hidden">
    <a-col :lg="5" :md="8" :xs="10">
      <a-affix v-if="ready" :offset-top="24">
        <a-card title="领域" :bodyStyle="{ padding: '0 0 40px 0' }" class="aside-wrapper">
          <a-tree
            v-if="fieldsOptions.length > 0"
            :selectedKeys.sync="field"
            :tree-data="fieldsOptions"
            default-expand-all
            :replaceFields="{ title: 'name', key: 'id' }"
            @select="onSelect"
          ></a-tree>
        </a-card>
      </a-affix>
    </a-col>

    <a-col :lg="19" :md="16" :xs="14">
      <a-card :bodyStyle="{ padding: 0 }">
        <div slot="title">
          <a-row type="flex" :gutter="[16, 16]">
            <a-col :span="7">
              <h-select
                v-model="query.status"
                allowClear
                placeholder="启用状态"
                @change="() => initData()"
                style="width: 100%"
              >
                <a-select-option v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
                  {{ statusInfo.label }}
                </a-select-option>
              </h-select>
            </a-col>

            <a-col :span="7">
              <h-select
                v-model="query.isRelationSkill"
                allowClear
                placeholder="关联状态"
                @change="() => initData()"
                style="width: 100%"
              >
                <a-select-option :value="1">关联</a-select-option>
                <a-select-option :value="0">未关联</a-select-option>
              </h-select>
            </a-col>

            <a-col :span="10">
              <h-select
                v-model="query.skillName"
                allowClear
                placeholder="请输入并选择技能包"
                style="flex: 1; width: 100%"
                optionFilterProp="children"
                :showSearch="true"
                @change="initData"
              >
                <a-select-option v-for="item in skillList" :key="item" :value="item" :title="item">
                  {{ item }}
                </a-select-option>
              </h-select>
            </a-col>
          </a-row>
        </div>
        <a-space slot="extra" style="margin-left: 16px">
          <a-button v-if="$auth('subjects_publish')" :disabled="!sort" type="primary" shape="round" @click="handleSort">
            <a-icon type="rocket" />发布
          </a-button>
          <a-button v-if="$auth('subjects_add')" type="primary" shape="round" @click="$router.push('/subject/create')">
            <a-icon type="plus" />新增题
          </a-button>
        </a-space>

        <subject-table
          v-if="$auth('subjects_list')"
          ref="table"
          @sort="sort = true"
          @getSkillList="getSkillList"
        ></subject-table>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import * as commonApi from '@/api/common'
import * as subjectApi from '@/api/subject'
import storage from 'store'
import { COMMON_STATUS } from '@/store/dictionary'
import SubjectTable from './components/Table'

export default {
  name: 'SubjectManagementList',
  components: {
    SubjectTable,
  },
  data() {
    return {
      ready: false,
      query: {
        fieldId: undefined,
        mainFieldId: undefined,
        status: undefined,
        isRelationSkill: undefined,
        skillName: undefined,
      },
      field: [],
      fieldsOptions: [],
      statusOptions: COMMON_STATUS,
      sort: false,
      skillList: [],
    }
  },
  activated() {
    if (this.ready) {
      this.initOptions()
    }

    this.ready = false

    this.$nextTick(() => {
      this.ready = true
    })
  },
  mounted() {
    const { $nextTick, initOptions } = this

    $nextTick(() => {
      this.ready = true
    })

    initOptions().then(() => this.initData())
  },
  methods: {
    getSkillList(list) {
      this.skillList = list
    },
    initData() {
      const {
        query,
        query: { isRelationSkill },
        $refs: { table },
      } = this

      this.skillList = []
      this.sort = false

      table.initData({ ...query, isRelationSkill: !isNaN(isRelationSkill) ? !!isRelationSkill : null })
    },
    initOptions() {
      return commonApi.fieldsOptions().then((res) => {
        this.fieldsOptions = res.data.filter((v) => v.level === 'MAIN')
        this.field = [this.fieldsOptions[0].id]
        this.query.mainFieldId = this.fieldsOptions[0].id
      })
    },
    onSelect(selectedKeys, info) {
      console.log(selectedKeys)
      if (selectedKeys.length > 0) {
        const { initData } = this

        const selected = selectedKeys[0]
        const parent = selected ? info.node.$parent.eventKey : undefined

        if (parent) {
          this.query.mainFieldId = parent
          this.query.fieldId = selected
        } else if (selected) {
          this.query.mainFieldId = selected
          this.query.fieldId = undefined
        } else {
          this.query.mainFieldId = undefined
          this.query.fieldId = undefined
        }

        initData()
      } else {
        const { mainFieldId, fieldId } = this.query
        const old = []
        fieldId ? old.push(fieldId) : old.push(mainFieldId)
        this.field = old
      }
    },
    handleSort() {
      const { $refs, $notification, initData } = this

      let list = $refs.table.list
      list = list.map((v) => ({ id: v.id, sequence: v.sequence }))

      this.sortLoading = true
      subjectApi
        .sort(list)
        .then(() => {
          this.sort = false
          $notification['success']({ message: '操作成功' })
          initData()
        })
        .finally(() => (this.sortLoading = false))
    },
  },

  beforeRouteLeave(to, from, next) {
    // 清空滚动条位置
    to.name !== 'SubjectManagementEdit' && storage.remove('subject_scroll_top')
    next()
  },
}
</script>

<style lang="less" scoped>
.aside-wrapper {
  height: calc(100vh - 48px);
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
}
/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}

/deep/ .ant-tree li.ant-tree-treenode-switcher-close,
/deep/ .ant-tree li.ant-tree-treenode-switcher-open {
  position: relative;
}
/deep/ .ant-tree li span.ant-tree-switcher {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(67%, 33%);
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper {
  padding: 0;
  padding-left: 40px;
  border-radius: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: @tag-color;
  color: @primary-color;
}
/deep/ .ant-tree > li:first-child {
  padding-top: 0;
}
/deep/ .ant-tree li {
  padding: 0;
}
/deep/ .ant-tree li ul {
  padding: 0;
  .ant-tree-node-content-wrapper {
    padding-left: 58px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
